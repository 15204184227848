import cx from "classnames";
import { FC } from "react";
import st from "./SliderArrow.module.scss";

interface Props {
  isLeft?: boolean;
  onClick?: () => void;
}

export const SliderArrow: FC<Props> = ({ isLeft, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={cx(st.container, isLeft ? st.prev : st.next)}
    >
      <div className={isLeft ? st["left-chevron"] : st["right-chevron"]} />
    </div>
  );
};

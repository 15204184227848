import { FC } from "react";
import { colors } from "../../styles/variables";
import styled from "styled-components";

const StyledSvg = styled.svg`
  fill: ${({ fill, theme }) =>
    fill || theme.color.primary || colors["darkGray"]};
`;

interface MinusIconSvgProps extends React.SVGProps<SVGSVGElement> {
  color?: string;
}

export const MinusIconSvg: FC<MinusIconSvgProps> = ({
  color,
  className,
  ref,
  ...props
}) => {
  const fill = color || colors["darkGray"];

  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="3"
      viewBox="0 0 12 3"
      className="fill-blue-950"
      {...props}
    >
      <path
        fill={fill}
        d="M0.010498 2.17736V0.823242H11.9896V2.17736H0.010498Z"
      />
    </StyledSvg>
  );
};

import Link from "next/link";
import { FC } from "react";
import { Image } from "react-datocms";
import { LinksAsCardsComponentFragment } from "services/datocms/generated";

type QuickLinksBlockProps = {
  data: LinksAsCardsComponentFragment;
};

export const QuickLinksBlock: FC<QuickLinksBlockProps> = ({ data }) => {
  return (
    <div className="py-10 md:py-20 px-6 bg-freshBlue flex flex-col items-center gap-10">
      <h1>{data.title}</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 w-full">
        {data.links.map((item) => (
          <Link
            href={item.url ?? ""}
            key={item.id}
            className="p-6 bg-white rounded-xl flex-col justify-start items-center gap-6 inline-flex cursor-pointer"
          >
            <div className="w-20 h-20 justify-center items-center inline-flex">
              {item.image?.responsiveImage && (
                <div className="w-20 h-20 relative">
                  <Image data={item.image.responsiveImage} />
                </div>
              )}
            </div>
            <div className="self-stretch text-center text-blue-950 text-base font-semibold leading-[21px]">
              {item.text}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

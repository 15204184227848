import { NewButton } from "components/NewButton/NewButton";
import Link from "next/link";
import { FC, useState } from "react";
import { StructuredText } from "react-datocms/structured-text";
import { FaqComponentFragment } from "services/datocms/generated";
import { twMerge } from "tailwind-merge";

type FaqQuestionProps = {
  question: FaqComponentFragment["questions"][number];
};

export const FaqQuestion: FC<FaqQuestionProps> = ({ question }) => {
  const { button, answer } = question;

  const [open, setOpen] = useState(false);

  return (
    <div className="py-6">
      <div
        onClick={() => setOpen(!open)}
        className="justify-between items-center gap-6 flex cursor-pointer"
      >
        <h5 className="block grow shrink basis-0 text-blue-950 text-[16px] font-semibold">
          {question.question}
        </h5>
        <div className="justify-start items-start gap-4 inline-flex">
          {button && (
            <Link href={button.url ?? ""}>
              <NewButton variant="outlined" className="hidden md:inline-flex">
                {button.title}
              </NewButton>
            </Link>
          )}

          <div
            className={twMerge(
              "w-12 h-12 bg-cyan-800 rounded-full justify-center items-center gap-2.5 inline-flex transition-all",
              open && "rotate-180",
            )}
          >
            <div className="w-6 h-6 flex-col justify-center items-center inline-flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="8"
                viewBox="0 0 16 8"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.835938 1.56431L2.1645 0.0693359L8.00022 5.25546L13.8359 0.0693359L15.1645 1.56431L8.00022 7.9311L0.835938 1.56431Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div
        className={twMerge(
          "overflow-hidden max-h-0 transition-all",
          open && "max-h-[500px] mt-4",
        )}
      >
        <StructuredText data={answer?.value} />
      </div>
    </div>
  );
};

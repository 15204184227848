import { AppContext } from "context/app";
import { useProductSliderAnalytics } from "hooks/analytics/useProductSliderAnalytics";
import { useScreenSize } from "hooks/useScreenSize";
import { FC, useContext } from "react";
import Slider from "react-slick";
import { ProductsSliderComponentFragment } from "services/datocms/generated";
import { useGetItemsByGtinQuery } from "services/graphql/generated";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import st from "./ProductsSlider.module.scss";
import { ProductsSliderItem } from "./ProductsSliderItem";
import { SliderArrow } from "./SliderArrow";

type ProductsSliderProps = {
  data: ProductsSliderComponentFragment;
};

export const ProductsSlider: FC<ProductsSliderProps> = ({ data }) => {
  const { data: allPepdirectItemsQuery, loading } = useGetItemsByGtinQuery({
    variables: { gtins: data.products.map((product) => product.gtin ?? "") },
  });
  const { isMobile, isTablet } = useScreenSize();
  const { header, products } = data;
  const { tenant } = useContext(AppContext);
  const currency = tenant?.checkout?.paymentOptions?.defaultCurrencyCode || "";

  const { handleSliderClickLogging } = useProductSliderAnalytics(
    allPepdirectItemsQuery,
    products,
    currency,
  );

  const slidesToShow = isMobile ? 2 : isTablet ? 3 : 5;

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    prevArrow: <SliderArrow isLeft />,
    nextArrow: <SliderArrow />,
  };

  return (
    <div
      style={{ backgroundColor: data.backgroundColor?.hex ?? "transparent" }}
    >
      <div className="container flex flex-col gap-10 py-10 md:py-20 px-6">
        <span className={st.header}>{header}</span>
        <div className={st.sliderContainer}>
          <Slider {...settings}>
            {products.map((product, idx) => (
              <ProductsSliderItem
                key={product.id}
                datoProduct={product}
                pepdirectProduct={allPepdirectItemsQuery?.items?.items?.find(
                  (item) => item.gtin === product?.gtin,
                )}
                handleLogging={() => handleSliderClickLogging(product, idx)}
                currency={currency}
                lowestPriceText={product?.lowestPriceText ?? ""}
                loading={loading}
              />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

import { FC } from "react";
import { Video, VideoPlayer } from "react-datocms/video-player";
import st from "./CustomVideoPlayer.module.scss";

export const CustomVideoPlayer: FC<{ data: Video }> = ({ data }) => {
  return (
    <VideoPlayer
      className={st.customVideoPlayer}
      accentColor="#75A7AD"
      data={data}
    />
  );
};

import { MinusIconSvg } from "components/icons/MinusIconSvg";
import { PlusIconSvg } from "components/icons/PlusIconSvg";
import { NewButton } from "components/NewButton/NewButton";
import { useTranslationContext } from "context/translation";
import { FC, useState } from "react";

interface AddToCartButtonProps {
  updatingCart: boolean;
  quantity: number;
  outOfStock: boolean;
  handleAdjustItemCount: (add: boolean) => void;
  loading: boolean;
}

const AddToCartButton: FC<AddToCartButtonProps> = ({
  updatingCart,
  quantity,
  outOfStock,
  handleAdjustItemCount,
  loading,
}) => {
  const { addToCart, comingSoon, added, removed } = useTranslationContext();
  const [lastAction, setLastAction] = useState<
    "added" | "removed" | undefined
  >();

  const addToCartButtonText = loading
    ? ""
    : outOfStock
      ? comingSoon
      : addToCart;

  return (
    <>
      {!updatingCart && quantity < 1 && (
        <NewButton
          disabled={outOfStock || loading}
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            setLastAction("added");
            handleAdjustItemCount(true);
          }}
          className="mt-4"
        >
          {addToCartButtonText}
        </NewButton>
      )}
      {!updatingCart && quantity > 0 && (
        <div
          className="flex w-[140px] md:w-[170px] h-[48px] content-center justify-around rounded-full border border-black cursor-auto mt-4"
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
          }}
        >
          <button
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              setLastAction("removed");
              handleAdjustItemCount(false);
            }}
          >
            <MinusIconSvg color={"#005D6E"} />
          </button>

          <div className="text-[15px] text-[#005D6E] font-black self-center">
            {quantity}{" "}
          </div>

          <button
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              setLastAction("added");
              handleAdjustItemCount(true);
            }}
          >
            <PlusIconSvg color={"#005D6E"} />
          </button>
        </div>
      )}
      {updatingCart && (
        <NewButton disabled={true} variant="outlined" className="mt-4">
          {lastAction === "removed" ? removed : added}
        </NewButton>
      )}
    </>
  );
};

export default AddToCartButton;

import { FC } from "react";
import {
  FaqComponentFragment,
  TabFaqComponentFragment,
} from "services/datocms/generated";
import { FaqQuestion } from "./FaqQuestion";

type FAQProps = {
  data: FaqComponentFragment | TabFaqComponentFragment;
};

export const Faq: FC<FAQProps> = ({ data }) => {
  return (
    <div className="container pt-10 pb-12 md:pt-20 md:pb-[100px] flex flex-col justify-start gap-6">
      <span className="text-center text-3xl text-blue-950 font-semibold">
        {data.title}
      </span>
      <div className="divide-y">
        {data.questions.map((question) => (
          <FaqQuestion key={question.id} question={question} />
        ))}
      </div>
    </div>
  );
};

/* eslint-disable @next/next/no-img-element */
import { CustomVideoPlayer } from "components/common/CustomVideoPlayer/CustomVideoPlayer";
import Link from "next/link";
import { FC } from "react";
import { Image } from "react-datocms";
import { HeroImageComponentFragment } from "services/datocms/generated";

type HeroImageSectionProps = {
  content: HeroImageComponentFragment;
  position?: number;
};

export const HeroImageSection: FC<HeroImageSectionProps> = ({
  content,
  position,
}) => {
  const { link, media } = content;

  const renderContent = () => {
    if (media?.video) {
      return <CustomVideoPlayer data={media.video} />;
    } else if (media?.responsiveImage) {
      return (
        <Image
          objectFit="cover"
          layout="responsive"
          data={media.responsiveImage}
        />
      );
    }
    return null;
  };

  if (!link) {
    return (
      <div
        bi-visibility=""
        bi-type={`Home Page Banner ${position}`}
        bi-promo-name={content.analyticsPromoName}
        bi-promo-position={`p-${position}`}
        bi-promo-creative={media?.url}
      >
        {renderContent()}
      </div>
    );
  }

  return (
    <Link
      className="w-full block"
      href={link}
      bi-visibility=""
      bi-type={`Home Page Banner ${position}`}
      bi-promo-name={content.analyticsPromoName}
      bi-promo-position={`p-${position}`}
      bi-promo-creative={media?.url}
    >
      {renderContent()}
    </Link>
  );
};

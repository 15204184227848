import cx from "classnames";
import { InformationIconSvg } from "components/icons/InformationIconSvg";
import { useTranslationContext } from "context/translation";
import { useState } from "react";
import { SplitHeroComponentFragment } from "services/datocms/generated";
import st from "./SplitHeroWithTextAndImage.module.scss";

type Option = {
  period: "string";
  value: "string";
};

export const SplitHeroWithTextAndImage = ({
  data,
}: {
  data: SplitHeroComponentFragment;
}) => {
  const options: Option[] = data.options;

  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const { homepagePopup } = useTranslationContext();

  return (
    <div
      style={{
        backgroundColor: data.backgroundColor?.hex ?? "transparent",
      }}
    >
      <div className="container" bi-visibility="" bi-type="Gimmick Section">
        <div className={st.row}>
          <div className={cx(st.column, st.textContent)}>
            <h2 className={st.header} style={{ color: data.headerColor?.hex }}>
              {data.header}
            </h2>
            <p
              className={st.bodyText}
              style={{ color: data.bodyTextColor?.hex }}
            >
              {data.bodyText}{" "}
              <span
                className={st.periodText}
                onClick={() => setPopoverOpen(!popoverOpen)}
              >
                {selectedOption.period}
                {popoverOpen && (
                  <div className={st.periodPopover}>
                    {options.map((option) => {
                      return (
                        <div
                          onClick={() => {
                            setSelectedOption(option);
                          }}
                          className={st.periodPopoverItem}
                          key={option.period}
                        >
                          {option.period}
                        </div>
                      );
                    })}
                  </div>
                )}
              </span>{" "}
              {selectedOption.value}
              <span className={st.infoBox}>
                <InformationIconSvg />
                <span className={st.modal}>{homepagePopup}</span>
              </span>
            </p>
          </div>

          <div className={cx(st.column, st.imageContent)}>
            <div className={cx(st.imageAnimate, st.imageAnimateFront)} />
            <div className={cx(st.imageAnimate, st.imageAnimateBack)} />
            <div
              style={{ backgroundImage: `url(${data.image?.url})` }}
              className={st.imageContainer}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

import cx from "classnames";
import { FC } from "react";
import { ExternalVideoComponentFragment } from "services/datocms/generated";

type ExternalVideoProps = {
  content: ExternalVideoComponentFragment;
};

export const ExternalVideo: FC<ExternalVideoProps> = ({ content }) => {
  if (!content.videoUrl?.url) {
    return null;
  }

  const aspectRatio = content.videoUrl.width / content.videoUrl.height;

  let videoUrl = "";
  if (content.videoUrl?.provider === "youtube") {
    const videoId = content.videoUrl?.url.split("v=")[1];
    videoUrl = `https://www.youtube.com/embed/${videoId}?autoplay=1&loop=1&playlist=${videoId}&controls=0`;
  } else if (content.videoUrl?.provider === "vimeo") {
    const vimeoId = content.videoUrl?.url.split(".com/")[1];
    videoUrl = `https://player.vimeo.com/video/${vimeoId}?autoplay=1&loop=1&background=1`;
  }

  return (
    <div
      style={{ aspectRatio: aspectRatio }}
      className={cx(!content.fullPageWidth && "container")}
    >
      <iframe
        width="100%"
        height="100%"
        src={videoUrl}
        title={content.videoUrl.title}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
    </div>
  );
};

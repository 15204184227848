import { FC } from "react";
import { Image, ImagePropTypes } from "react-datocms";

interface CustomDatoImageProps extends ImagePropTypes {
  width?: number;
}

export const CustomDatoImage: FC<CustomDatoImageProps> = ({
  width,
  data,
  ...restProps
}) => {
  if (!data) {
    return;
  }
  const copyData = { ...data };
  if (width) {
    const resizedImageUrl = (copyData.src += `&w=${width}&dpr=2 2x`);
    copyData.srcSet = `${resizedImageUrl}, ${copyData.srcSet}`;
  }
  return (
    <Image
      data={copyData}
      fadeInDuration={0}
      intersectionMargin="100px 0px 0px 0px"
      {...restProps}
    />
  );
};

import cx from "classnames";
import { CustomStructuredText } from "components/CustomStructuredText/CustomStructuredText";
import NextImage from "next/image";
import { Image } from "react-datocms";
import { ColumnContentComponentFragment } from "services/datocms/generated";

export const ColumnItem = ({
  item,
  index,
  showItemNumber,
  centerContent,
}: {
  item: ColumnContentComponentFragment["mediaItems"][number];
  index: number;
  showItemNumber?: boolean;
  centerContent?: boolean;
}) => {
  const { video, url } = item.media ?? {};

  const renderImg = (
    media: ColumnContentComponentFragment["mediaItems"][number]["media"],
  ) => {
    if (media?.responsiveImage) {
      return <Image data={media?.responsiveImage} />;
    }
    if (media?.url) {
      return (
        <NextImage
          src={media.url}
          height={100}
          width={100}
          alt={media.alt ?? ""}
        />
      );
    }

    return <></>;
  };

  return (
    <div
      className={cx(
        "flex flex-1 flex-col h-full gap-6",
        centerContent && "items-center",
      )}
    >
      {video ? (
        <video
          playsInline
          muted
          loop
          className="h-auto w-full rounded-lg"
          poster={video.thumbnailUrl}
          onMouseOver={(event) => (event.target as HTMLVideoElement).play()}
          onMouseOut={(event) => {
            const target = event.target as HTMLVideoElement;
            target.currentTime = 0;
            target.pause();
          }}
        >
          <source src={url} type="video/mp4"></source>
        </video>
      ) : (
        <>{renderImg(item.media)}</>
      )}
      <div className="gap-2 mb-12 lg:mb-0 flex flex-col">
        <div
          className={cx(
            "flex gap-4 items-center text-primary",
            centerContent && "items-center flex-col",
          )}
        >
          {showItemNumber && (
            <div className="flex justify-center items-center w-9 h-9 circle text-white rounded-full font-semibold text-xl bg-terraCottaRed">
              {index + 1}
            </div>
          )}
          {item.header && (
            <div className="font-semibold text-xl">{item.header}</div>
          )}
        </div>
        <CustomStructuredText
          className={centerContent ? "text-center" : "text-left"}
          content={item.text}
        />
      </div>
    </div>
  );
};

import cx from "classnames";
import Link from "next/link";
import { FC } from "react";
import { Image } from "react-datocms";
import { SmallTilesComponentFragment } from "services/datocms/generated";
import st from "./SmallTilesSection.module.scss";

type SmallTilesSectionProps = {
  content?: SmallTilesComponentFragment;
};

export const SmallTilesSection: FC<SmallTilesSectionProps> = ({ content }) => {
  return (
    <div
      style={{
        backgroundColor: content?.backgroundColor?.hex ?? "transparent",
      }}
    >
      <div className="container">
        <div className={st.row}>
          {content?.smallTiles.map((tile) => (
            <div
              key={tile.id}
              className={cx(st.column, st.tile)}
              bi-visibility=""
              bi-type={tile.analyticsType}
              bi-id="small_tile_wrapper"
            >
              {/* {tile.header} */}
              <div className={st.left}>
                <div className={st.leftContent}>
                  <h3 className={st.header}>{tile.header}</h3>
                  <p className={st.bodyText}>{tile.bodyText}</p>
                  <Link
                    className={st.link}
                    href={tile.linkUrl ?? ""}
                    bi-type={tile.analyticsType}
                    bi-id="Shop now"
                  >
                    <span className={st.additionalText}>{tile.linkText}</span>
                    <span className={st.additionalArrow}>❯</span>
                  </Link>
                </div>
              </div>
              <div className={st.right}>
                {tile.image?.responsiveImage && (
                  <Image
                    className={st.image}
                    layout="responsive"
                    objectFit="cover"
                    data={tile.image?.responsiveImage}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

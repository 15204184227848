import Image from "next/image";
import Link from "next/link";

import st from "./featured-article.module.scss";

const FeaturedArticle = ({
  heroVisual,
  slug,
  title,
}: {
  slug?: string;
  heroVisual: string;
  title: string;
}) => {
  return (
    <Link href={`/blog/${slug}`} className={st.link}>
      <div className={st.featuredArticleContainer}>
        <Image
          src={heroVisual}
          alt="secondary article cover"
          className={st.coverImage}
          width={0}
          height={0}
          sizes="100vw"
        />
        <div className={st.summaryContainer}>
          <div>
            <span>{title}</span>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default FeaturedArticle;

import { CustomStructuredText } from "components/CustomStructuredText/CustomStructuredText";
import { FC } from "react";
import { RichTextComponentFragment } from "services/datocms/generated";

export const RichTextBlock: FC<{
  content: RichTextComponentFragment;
  className?: string;
}> = ({ content, className }) => {
  return (
    <div className={"p-6 flex justify-center w-full"}>
      <CustomStructuredText className={className} content={content} />
    </div>
  );
};

import { NewButton } from "components/NewButton/NewButton";
import Link from "next/link";
import { FC } from "react";
import { Image } from "react-datocms";
import { BigTilesComponentFragment } from "services/datocms/generated";
import st from "./BitTilesSection.module.scss";

type BigTilesSectionProps = {
  content?: BigTilesComponentFragment;
};

export const BigTilesSection: FC<BigTilesSectionProps> = ({ content }) => {
  return (
    <div
      style={{
        backgroundColor: content?.backgroundColor?.hex ?? "transparent",
      }}
    >
      <div className="container">
        <div className={st.row}>
          {content?.bigTiles.map((tile) => (
            <div
              key={tile.id}
              className={st.column}
              bi-visibility=""
              bi-type={tile.analyticsType}
              bi-id="big_tile_wrapper"
            >
              {tile.backgroundImage?.responsiveImage && (
                <Image
                  layout="responsive"
                  objectFit="cover"
                  data={tile.backgroundImage?.responsiveImage}
                />
              )}
              {/* <img src={tile.backgroundImage?.url} alt="" /> */}
              {/* {tile.header} */}
              <div className={st.tileContent}>
                <div className={st.leftContent}>
                  <h2
                    className={st.header}
                    style={{ color: tile.headerColor?.hex }}
                  >
                    {tile.header}
                  </h2>
                  {tile.links && (
                    <div className={st.linksContainer}>
                      {tile.links.map((link) => (
                        <Link
                          className={st.link}
                          href={link.url ?? ""}
                          key={link.id}
                        >
                          <span className={st.additionalText}>
                            {link.title}
                          </span>
                          <span className={st.additionalArrow}>❯</span>
                        </Link>
                      ))}
                    </div>
                  )}
                  <Link
                    href={tile.linkButton?.url ?? ""}
                    bi-type={tile.analyticsType}
                    bi-id="SHOP NOW"
                  >
                    <NewButton
                      style={{ backgroundColor: tile.linkButton?.color?.hex }}
                      className={st.button}
                    >
                      <span className={st.buttonText}>
                        {tile.linkButton?.title}
                      </span>
                    </NewButton>
                  </Link>
                </div>
                <div className={st.rightContent}>
                  {tile.foregroundImages.map((image) => {
                    if (!image.responsiveImage) {
                      return null;
                    }
                    return (
                      <div
                        key={image.id}
                        style={{
                          width: 100 / tile.foregroundImages.length + "%",
                        }}
                      >
                        <Image
                          layout="responsive"
                          data={image.responsiveImage}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

import cx from "classnames";
import { FC } from "react";
import { ColumnContentComponentFragment } from "services/datocms/generated";
import { ColumnItem } from "./ColumnItem";

export const ColumnContentBlock: FC<{
  content: ColumnContentComponentFragment;
}> = ({ content }) => {
  const { backgroundColor, title, mediaItems, itemWidth, alignContent } =
    content;

  return (
    <div
      style={{
        backgroundColor: backgroundColor?.hex ?? "transparent",
      }}
    >
      <div className="container flex flex-col gap-10 py-10 md:py-20 px-6">
        {title && <h2 className="text-center text-primary">{title}</h2>}
        <div
          className={cx(
            "flex -mx-6 flex-wrap",
            alignContent === "center" && "justify-center",
            alignContent === "end" && "justify-end",
          )}
        >
          {mediaItems.map((item, index) => (
            <div
              key={item.id}
              className={cx(
                "flex w-12/12 md:w-6/12 lg:4/12 px-6",
                itemWidth === "2/12" && "lg:w-2/12",
                itemWidth === "3/12" && "lg:w-3/12",
                itemWidth === "4/12" && "lg:w-4/12",
                itemWidth === "6/12" && "lg:w-6/12",
              )}
            >
              <ColumnItem
                showItemNumber={content.showItemNumber}
                centerContent={content.centerItemContent}
                item={item}
                index={index}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

import { FeaturedBlogPostComponentFragment } from "services/datocms/generated";
import FeaturedArticle from "./FeaturedArticle";

import st from "./featured-articles.module.scss";

interface FeaturedArticlesProps {
  content: FeaturedBlogPostComponentFragment;
}

export const FeaturedArticles: React.FC<FeaturedArticlesProps> = ({
  content,
}) => {
  return (
    <div
      style={{
        backgroundColor: content.backgroundColor?.hex,
        padding: "100px 0",
      }}
      bi-visibility=""
      bi-type="Article"
    >
      <div className="container">
        <h2 className={st.header}>{content.header}</h2>
        <h3 className={st.subHeader}>{content.subHeader}</h3>
        <div className={st.featuredArticlesContainer}>
          {content.articles.map((article) => (
            <FeaturedArticle
              key={article?.id}
              heroVisual={article.heroVisual?.url || ""}
              slug={`${article.section?.slug}/${article.slug}` || ""}
              title={article?.title || ""}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

import { FC } from "react";
import { SplitContentComponentFragment } from "services/datocms/generated";
import { SplitContentImage } from "./SplitContentImage";
import { SplitContentText } from "./SplitContentText";

export const SplitContentBlock: FC<{
  content: SplitContentComponentFragment;
}> = ({ content }) => {
  return (
    <div
      style={{
        backgroundColor: content.backgroundColor?.hex ?? "transparent",
      }}
    >
      <div className="container mx-auto gap-6 grid grid-cols-1 lg:grid-cols-2 p-6">
        {content.content.map((item) => {
          if (item.__typename === "SplitContentImageRecord") {
            return <SplitContentImage key={item.id} content={item} />;
          } else {
            return <SplitContentText key={item.id} content={item} />;
          }
        })}
      </div>
    </div>
  );
};

import { FC } from "react";
import { useGetItemsByGtinQuery } from "services/graphql/generated";
import { CollectionProductListComponentFragment } from "services/datocms/generated";
import { DefaultProductListing } from "./DefaultProductListing";

type CollectionListingProps = {
  data: CollectionProductListComponentFragment;
  analyticsName?: string;
};

export const CollectionListing: FC<CollectionListingProps> = ({ data, analyticsName }) => {
  const { data: allPepdirectItemsQuery, loading } = useGetItemsByGtinQuery({
    variables: { gtins: data.products.map((product) => product.gtin ?? "") },
  });

  const pepdirectProducts = allPepdirectItemsQuery?.items?.items;

  return (
    <DefaultProductListing
      products={data.products}
      pepdirectProducts={pepdirectProducts}
      loading={loading}
      analyticsName={analyticsName}
    />
  );
};

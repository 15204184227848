import Head from "next/head";
import { endpoints } from "config";
import { FC, ReactNode } from "react";
import { renderMetaTags } from "react-datocms/seo";
import { SeoFragment } from "services/datocms/generated";
import { twMerge } from "tailwind-merge";

type PageLayoutProps = {
  children: ReactNode;
  navigationOverlapsContent?: boolean;
  className?: string;
  seo?: SeoFragment | null;
  noIndex?: boolean;
  canonicalOveridePath?: string | null;
};

const PageLayout: FC<PageLayoutProps> = ({
  children,
  navigationOverlapsContent,
  className,
  seo,
  noIndex,
  canonicalOveridePath,
}) => {
  const getCanonicalTag = () => {
    const currentLocation =
      typeof window !== "undefined" ? window?.location?.href : "";

    if (canonicalOveridePath) {
      return new URL(canonicalOveridePath, endpoints.web).href;
    } else {
      return currentLocation
        ?.split("?")?.[0]
        ?.split("#")?.[0]
        .toLocaleLowerCase();
    }
  };
  return (
    <>
      <Head>
        {seo && <>{renderMetaTags(seo.seoMeta)}</>}
        {noIndex && <meta name="robots" content="noindex" />}
        <link rel="canonical" href={getCanonicalTag()} />
      </Head>
      <div className="flex flex-col">
        <div
          className={twMerge(
            "pt-[198px] md:pt-[148px]",
            navigationOverlapsContent && "md:pt-[58px]",
            className,
          )}
        >
          {children}
        </div>
      </div>
    </>
  );
};

export default PageLayout;

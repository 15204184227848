import React from "react";

export const InformationIconSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M12 4.375C9.07031 4.375 6.41406 5.9375 4.92969 8.4375C3.48438 10.9766 3.48438 14.0625 4.92969 16.5625C6.41406 19.1016 9.07031 20.625 12 20.625C14.8906 20.625 17.5469 19.1016 19.0312 16.5625C20.4766 14.0625 20.4766 10.9766 19.0312 8.4375C17.5469 5.9375 14.8906 4.375 12 4.375ZM12 22.5C8.40625 22.5 5.125 20.625 3.32812 17.5C1.53125 14.4141 1.53125 10.625 3.32812 7.5C5.125 4.41406 8.40625 2.5 12 2.5C15.5547 2.5 18.8359 4.41406 20.6328 7.5C22.4297 10.625 22.4297 14.4141 20.6328 17.5C18.8359 20.625 15.5547 22.5 12 22.5ZM10.4375 15.625H11.375V13.125H10.4375H9.5V11.25H10.4375H12.3125H13.25V12.1875V15.625H13.5625H14.5V17.5H13.5625H10.4375H9.5V15.625H10.4375ZM13.25 10H10.75V7.5H13.25V10Z"
      fill="#002238"
    />
  </svg>
);

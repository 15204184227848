import Hero from "components/Hero/Hero";
import { ColumnContentBlock } from "components/ProductDetails/Content/ColumnContentBlock/ColumnContentBlock";
import { RichTextBlock } from "components/ProductDetails/Content/RichTextBlock/RichTextBlock";
import { SplitContentBlock } from "components/ProductDetails/Content/SplitContentBlock/SplitContentBlock";
import { FeaturedArticles } from "components/blog/FeaturedArticles";
import { ProductsSlider } from "components/homepage/BestSellerSection/ProductsSlider";
import { BigTilesSection } from "components/homepage/BigTilesSection/BigTilesSection";
import { HeroImageSection } from "components/homepage/HeroImageSection";
import { SmallTilesSection } from "components/homepage/SmallTilesSection/SmallTilesSection";
import { SplitHeroWithTextAndImage } from "components/homepage/SplitHeroWithTextAndImage/SplitHeroWithTextAndImage";
import { FC, useState } from "react";
import { TabsComponentFragment } from "services/datocms/generated";
import { ExternalVideo } from "../Dato/ExternalVideo";
import { QuickLinksBlock } from "../QuickLinksBlock/QuickLinksBlock";
import st from "./index.module.scss";
import { Faq } from "../Faq/Faq";
import styled from "styled-components";
import { Device } from "helpers/screenSizes";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

type NavContainerProps = {
  tabCount: number;
};

const NavContainer = styled.div<NavContainerProps>`
  display: flex;
  margin: 24px 0;
  border-radius: 9999px;
  background-color: #f5f5f5;
  min-width: 50%;

  @media ${Device.mobile} {
    justify-content: flex-start;
    width: 100vw;
    overflow-x: scroll;
  }

  @media ${Device.tablet} {
    justify-content: flex-start;
    width: 80vw;
    overflow-x: scroll;
  }
`;

const StyledTab = styled.span<NavContainerProps>`
  flex: 1;
  min-width: max-content;
  white-space: nowrap;
  text-align: center;
  font-family: "Poppins", sans-serif;
  padding: 10.5px 34.5px;
  margin: 4px 5px;
  border-radius: 9999px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
`;

type TabsProps = {
  component: TabsComponentFragment | undefined | null;
};

export const Tabs: FC<TabsProps> = ({ component }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  if (!component || !component.tabs) {
    return null;
  }

  const handleTabClick = (index: number) => {
    setSelectedTab(index);
  };

  let bannerComponentsCount = 0;

  const renderTabComponent = (block: any) => {
    if (!block) return null;

    switch (block.__typename) {
      case "HomepageHeroComponentRecord":
        if (!block.showOnMobile) {
          ++bannerComponentsCount;
        }
        return (
          <Hero key={block.id} data={block} position={bannerComponentsCount} />
        );
      case "ColumnContentComponentRecord":
        return <ColumnContentBlock key={block.id} content={block} />;
      case "HeroImageComponentRecord":
        ++bannerComponentsCount;
        return (
          <HeroImageSection
            key={block.id}
            content={block}
            position={bannerComponentsCount}
          />
        );
      case "RichTextComponentRecord":
        return <RichTextBlock key={block.id} content={block} />;
      case "ProductsSliderComponentRecord":
        return <ProductsSlider key={block.id} data={block} />;
      case "LinksAsCardsComponentRecord":
        return <QuickLinksBlock key={block.id} data={block} />;
      case "SplitContentComponentRecord":
        return <SplitContentBlock key={block.id} content={block} />;
      case "SplitHeroComponentRecord":
        return <SplitHeroWithTextAndImage key={block.id} data={block} />;
      case "BigTilesComponentRecord":
        return <BigTilesSection key={block.id} content={block} />;
      case "SmallTilesComponentRecord":
        return <SmallTilesSection key={block.id} content={block} />;
      case "FeaturedBlogPostComponentRecord":
        return <FeaturedArticles key={block.id} content={block} />;
      case "ExternalVideoRecord":
        return <ExternalVideo key={block.id} content={block} />;
      case "FaqComponentRecord":
        return <Faq key={block.id} data={block} />;
      default:
        return null;
    }
  };

  return (
    <>
      <Wrapper>
        <NavContainer tabCount={component.tabs.length}>
          {component.tabs.map((section, index) => (
            <StyledTab
              key={index}
              className={selectedTab === index ? st.activeLink : ""}
              onClick={() => handleTabClick(index)}
              tabCount={component.tabs.length}
            >
              {section.tabTitle}
            </StyledTab>
          ))}
        </NavContainer>
      </Wrapper>
      <div className="flex-1">
        {component.tabs[selectedTab]?.tabComponents &&
          renderTabComponent(component.tabs[selectedTab].tabComponents)}
      </div>
    </>
  );
};

import { AnalyticsData } from "@shared/types";
import {
  getAnalyticsCart,
  logAddToCartEvent,
  logRemoveFromCartEvent,
} from "helpers/analyticsLogger";
import { useRouter } from "next/router";
import { useCallback } from "react";
import { createDatoClient } from "services/datocms";
import { getSdk } from "services/datocms/generated";
import { CartFragment } from "services/graphql/generated";

export const useCartAnalyticsEvents = () => {
  const router = useRouter();

  const getAnalyticsCartWithCategories = useCallback(
    async (cart: CartFragment | null) => {
      const cartProductGtins: string[] = [];
      cart?.lineItems?.forEach((item) => {
        if (item.__typename === "CartItem") {
          cartProductGtins.push(item.item.gtin);
        } else if (item.__typename === "BundleLineItem") {
          item.primaryBundleItems?.forEach((bundleItem) =>
            cartProductGtins.push(bundleItem.item.gtin),
          );
        }
      });
      const { allProducts } = await getSdk(
        createDatoClient(router.isPreview),
      ).ProductsByGtin({ gtin: cartProductGtins });
      return getAnalyticsCart(cart, allProducts);
    },
    [router.isPreview],
  );

  const addToCartEvent = useCallback(
    async (
      cart: CartFragment | null,
      ecommerce: AnalyticsData["ecommerce"],
    ) => {
      const analyticsCart = await getAnalyticsCartWithCategories(cart);
      if (analyticsCart) {
        logAddToCartEvent(analyticsCart, ecommerce);
      }
    },
    [getAnalyticsCartWithCategories],
  );

  const removeFromCartEvent = useCallback(
    async (
      cart: CartFragment | null,
      ecommerce: AnalyticsData["ecommerce"],
    ) => {
      const analyticsCart = await getAnalyticsCartWithCategories(cart);
      if (analyticsCart) {
        logRemoveFromCartEvent(analyticsCart, ecommerce);
      }
    },
    [getAnalyticsCartWithCategories],
  );

  return { addToCartEvent, removeFromCartEvent };
};

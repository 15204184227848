import { PartialProduct } from "@shared/types";
import { ITEM_BRAND } from "config";
import {
  logProductListImpressionEvent,
  logSelectItemEvent,
} from "helpers/analyticsLogger";
import { useCallback, useEffect } from "react";
import { ProductsSliderComponentFragment } from "services/datocms/generated";
import { GetItemsByGtinQuery } from "services/graphql/generated";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const itemListName = "Product slider";

export const useProductSliderAnalytics = (
  allPepdirectItemsQuery: GetItemsByGtinQuery | undefined,
  products: ProductsSliderComponentFragment["products"],
  currency: string,
) => {
  const readySliderItemsForLogging = useCallback(
    (
      products: ProductsSliderComponentFragment["products"],
      currency: string,
      clickIdx?: number,
    ) => {
      return products.map((product, index) => {
        const additionalData = allPepdirectItemsQuery?.items?.items.find(
          (item) => item.gtin === product?.gtin,
        );
        return {
          itemId: additionalData?.id,
          gtin: additionalData?.gtin,
          itemName: additionalData?.title,
          itemBrand: ITEM_BRAND,
          itemCategory: product.category?.analyticsCategoryName,
          price: additionalData?.price ? additionalData.price / 100 : 0,
          itemListName: itemListName,
          currency,
          index: clickIdx ? clickIdx + 1 : index + 1,
        } as PartialProduct;
      });
    },
    [allPepdirectItemsQuery?.items?.items],
  );

  useEffect(() => {
    if (products.length && itemListName && currency && allPepdirectItemsQuery) {
      const items = readySliderItemsForLogging(products, currency);
      logProductListImpressionEvent(itemListName, currency, items);
    }
  }, [products, allPepdirectItemsQuery, currency, readySliderItemsForLogging]);

  const handleSliderClickLogging = useCallback(
    (
      item: ProductsSliderComponentFragment["products"][number],
      idx?: number,
    ) => {
      const items = readySliderItemsForLogging([item], currency, idx);
      logSelectItemEvent(itemListName, currency, items);
    },
    [currency, readySliderItemsForLogging],
  );

  return { handleSliderClickLogging };
};

import { ITEM_BRAND } from "config";
import { AppContext } from "context/app";
import {
  logProductListImpressionEvent,
  logSelectItemEvent,
} from "helpers/analyticsLogger";
import { FC, Fragment, useContext, useEffect, useMemo, useRef } from "react";
import {
  DatoProductForCategoryPageFragment,
} from "services/datocms/generated";
import { GetItemsByGtinQuery } from "services/graphql/generated";
import { SingleProduct } from "./SingleProduct";

interface DefaultProductListingProps {
  products: DatoProductForCategoryPageFragment[];
  pepdirectProducts?: NonNullable<GetItemsByGtinQuery["items"]>["items"];
  loading?: boolean;
  analyticsName?: string;
}

export const DefaultProductListing: FC<DefaultProductListingProps> = ({
  pepdirectProducts,
  products,
  loading = false,
  analyticsName
}) => {
  const currentIndexRef = useRef(0);

  const { tenant } = useContext(AppContext);
  const currency = tenant?.checkout?.paymentOptions?.defaultCurrencyCode || "";

  const itemListName = `Collection - ${analyticsName}`;
  const listingItemsForLogging = useMemo(() => {
    currentIndexRef.current = 0;
    return products.map((product) => {
      const additionalData = pepdirectProducts?.find(
        (item) => item.gtin === product?.gtin,
      );

      currentIndexRef.current += 1;

      return {
        itemId: additionalData?.id,
        gtin: additionalData?.gtin,
        itemName: additionalData?.title,
        itemBrand: ITEM_BRAND,
        itemCategory: product.category?.analyticsCategoryName || "",
        price: additionalData?.price ? additionalData.price / 100 : 0,
        itemListName: itemListName,
        currency,
        index: currentIndexRef.current,
      };
    });
  }, [
    currency,
    itemListName,
    pepdirectProducts,
    products,
  ]);

  useEffect(() => {
    if (currency && pepdirectProducts) {
      logProductListImpressionEvent(
        itemListName,
        currency,
        listingItemsForLogging,
      );
    }
  }, [currency, itemListName, listingItemsForLogging, pepdirectProducts]);

  const handleLogging = (itemId: string) => {
    const itemToLog = listingItemsForLogging.find(
      (loggedItem) => loggedItem.itemId === itemId,
    );

    if (itemToLog) {
      logSelectItemEvent(itemListName, currency, [
        { ...itemToLog, index: itemToLog.index },
      ]);
    }
  };

  return (
    <>
      <div className="container p-6 flex flex-col gap-10">
        <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 md:gap-6">
          {products.map((product, index) => (
            <Fragment key={index}>
              <SingleProduct
                datoProduct={product}
                pepdirectProduct={pepdirectProducts?.find(
                  (item) => item.gtin === product?.gtin,
                )}
                handleLogging={handleLogging}
                analytics={{
                  itemCategory: product.category?.analyticsCategoryName ?? "",
                  itemListName: itemListName ?? "",
                }}
                loading={loading}
              />
            </Fragment>
          ))}
        </div>
      </div>
    </>
  );
};

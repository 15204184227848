import { environment } from "config";

const INSTANCE_ID_CONFIG = {
    "production": {
        reviews: "868337",
        stars: "868340",
    },
    "other": {
        reviews: "814782",
        stars: "814807",
    }
}

  export const getYotpoInstanceId = (
    featureType: "reviews" | "stars",
  ): string => {
    if(environment === "production") {
        return INSTANCE_ID_CONFIG.production[featureType];
    }
    return INSTANCE_ID_CONFIG.other[featureType];
  };
  
import cx from "classnames";
import { FC } from "react";
import { StructuredText } from "react-datocms";
import {
  RichTextBlockFragment,
  RichTextComponentFragment,
} from "services/datocms/generated";
import styled from "styled-components";

const StructuredTextWrapper = styled.div<{
  headingTextSize: number;
  backgroundColor: string;
  textColor: string;
}>`
  background-color: ${(props) => props.backgroundColor};

  > h1,
  h2 {
    font-size: ${(props) => props.headingTextSize}px;
    font-weight: 600;
  }

  > p {
    font-size: 15px;
    font-weight: 500;
    color: ${(props) => props.textColor};
  }
`;

export const CustomStructuredText: FC<{
  content?: RichTextBlockFragment | RichTextComponentFragment | null;
  className?: string;
}> = ({ content, className }) => {
  if (!content) {
    return null;
  }

  const { textColor, textContent, headingTextSize, backgroundColor } = content;

  return (
    <StructuredTextWrapper
      className={cx(className, "flex flex-col gap-2 [&_a]:underline")}
      headingTextSize={headingTextSize ?? 32}
      backgroundColor={backgroundColor?.hex ?? "transparent"}
      textColor={textColor?.hex ?? ""}
    >
      <StructuredText data={textContent?.value} />
    </StructuredTextWrapper>
  );
};

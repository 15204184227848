import { useEffect, useState } from "react";

const MOBILE_BREAKPOINT = 767;
const TABLET_MIN = 768;
const TABLET_MAX = 1024;
const LAPTOP_MIN = 1025;

export function useScreenSize(): {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
} {
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const isClient = typeof window === "object";
    if (!isClient) {
      return;
    }

    function handleResize() {
      if (isClient) {
        setIsMobile(window.innerWidth <= MOBILE_BREAKPOINT);
        setIsTablet(
          window.innerWidth >= TABLET_MIN && window.innerWidth <= TABLET_MAX,
        );
        setIsDesktop(window.innerWidth > LAPTOP_MIN);
      } else {
        setIsMobile(true);
        setIsTablet(true);
        setIsDesktop(true);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return {
    isMobile,
    isTablet,
    isDesktop,
  };
}

import { FC } from "react";
import { colors } from "../../styles/variables";
import styled from "styled-components";

const StyledSvg = styled.svg`
  fill: ${({ fill, theme }) =>
    fill || theme.color.primary || colors["darkGray"]};
`;

interface PlusIconSvgProps extends React.SVGProps<SVGSVGElement> {
  color?: string;
}

export const PlusIconSvg: FC<PlusIconSvgProps> = ({
  color,
  className,
  ref,
  ...props
}) => {
  const fill = color || colors["darkGray"];

  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      className="fill-primary"
      {...props}
    >
      <path
        fill={fill}
        d="M5.32294 11.9896V6.67711H0.010437V5.323H5.32294V0.010498H6.67705V5.323H11.9896V6.67711H6.67705V11.9896H5.32294Z"
      />
    </StyledSvg>
  );
};

import Link from "next/link";
import { FC } from "react";
import { HomepageHeroComponentFragment } from "services/datocms/generated";
import st from "./Hero.module.scss";
import classNames from "classnames";
import Head from "next/head";

type HeroProps = {
  data: HomepageHeroComponentFragment;
  position?: number;
};

const Hero: FC<HeroProps> = ({ data, position }) => {
  const getFlexPositionVertical = (position: string): string => {
    switch (position) {
      case "top":
        return "flex-start";
      case "bottom":
        return "flex-end";
      case "center":
        return "center";
      default:
        return "center";
    }
  };

  const getFlexPositionHorizontal = (position: string): string => {
    switch (position) {
      case "right":
        return "flex-end";
      case "left":
        return "flex-start";
      case "center":
        return "center";
      default:
        return "flex-start";
    }
  };

  const getHorizontalStyles = (
    position: string,
  ): { paddingLeft?: string; paddingRight?: string } => {
    switch (position) {
      case "right":
        return { paddingRight: "20%" };
      case "left":
        return { paddingLeft: "20%" };
      case "center":
        return {};
      default:
        return {};
    }
  };

  const getTextAlign = (position: string): "right" | "left" | "center" => {
    switch (position) {
      case "right":
        return "right";
      case "center":
        return "center";
      case "left":
        return "left";
      default:
        return "left";
    }
  };

  const textColor = data.textColor?.hex ?? "#fff";
  const imgSrc = `${data?.backgroundImage?.url}?auto=format`;
  const buttonBackgroundColor = data.buttonBackgroundColor?.hex ?? "#002238";
  const buttonTextColor = data.buttonTextColor?.hex ?? "#FFFFFF";

  return (
    <>
      <Head>
        <link
          /* Since we are not using next.js nor datocms image for that, it's easier to use Head to preload images */
          /* TODO: also preload first hero only ? can we have more than 1 hero on the page? */
          rel="preload"
          href={imgSrc}
          as="image"
          fetchPriority="high"
          media={
            data.showOnMobile ? "(max-width: 767px)" : "(min-width: 768px)"
          }
        />
      </Head>
      <div
        /* We removed the useIsMobile hook and rely on css-media-query because it lets us avoid flash of mobile 
          content and we don't load unneeded images this way anyway  */
        className={classNames(st.container, {
          [st.showMobile]: data.showOnMobile,
          [st.hideMobile]: !data.showOnMobile,
        })}
        style={{
          backgroundImage: `url(${imgSrc})`,
          backgroundPosition: `${data?.imageXCenter}% ${data?.imageYCenter}%`,
          alignItems: getFlexPositionVertical(data?.verticalPosition || ""),
        }}
        bi-visibility=""
        bi-type={`Home Page Banner ${position}`}
        bi-promo-name={data.analyticsPromoName}
        bi-promo-position={`p-${position}`}
        bi-promo-creative={imgSrc}
      >
        <div
          className={classNames(st.content, st.paddingResetOnMobile)}
          style={{
            alignItems: getFlexPositionHorizontal(
              data?.horizontalPosition || "",
            ),
            ...getHorizontalStyles(data?.horizontalPosition || ""),
          }}
        >
          <h1
            className={st.heading}
            style={{
              textAlign: getTextAlign(data?.horizontalPosition || ""),
              color: textColor,
            }}
          >
            {data?.header}
          </h1>
          <span
            className={st.description}
            style={{
              textAlign: getTextAlign(data?.horizontalPosition || ""),
              color: textColor,
            }}
          >
            {data?.description}
          </span>
          <Link href={data?.link || ""}>
            <button
              className={st.button}
              style={{
                backgroundColor: buttonBackgroundColor,
                color: buttonTextColor,
              }}
            >
              {data?.linkText}
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Hero;

import {
  DynamicBundleFragment,
  GetItemsByGtinQuery,
  ItemByGtinQuery,
} from "services/graphql/generated";

export const isProductOutOfStock = (
  pepdirectProduct?:
    | ItemByGtinQuery["itemByGtin"]
    | NonNullable<GetItemsByGtinQuery["items"]>["items"][number],
  pepdirectBundle?: DynamicBundleFragment | null,
) => {
  if (pepdirectBundle) {
    return pepdirectBundle.inventoryQty === 0;
  }
  return pepdirectProduct?.isOutOfStock || !pepdirectProduct?.inventoryQty;
};

export const getProductPrice = (
  pepdirectProduct?:
    | ItemByGtinQuery["itemByGtin"]
    | NonNullable<GetItemsByGtinQuery["items"]>["items"][number],
  pepdirectBundle?: DynamicBundleFragment | null,
) => {
  if (pepdirectBundle) {
    return pepdirectBundle.discountPrice;
  } else {
    return pepdirectProduct?.price;
  }
};

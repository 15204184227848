import { ITEM_BRAND } from "config";
import { CartContext } from "context/cart";
import { LocalizationContext } from "context/localization";
import { getEcommerceWithValue } from "helpers/analyticsLogger";
import { isProductOutOfStock } from "helpers/product";
import { useCartAnalyticsEvents } from "hooks/analytics/useCartAnalyticsEvents";
import Link from "next/link";
import { useRouter } from "next/router";
import { FC, memo, useContext, useState } from "react";
import { Image } from "react-datocms";
import { ProductsSliderComponentFragment } from "services/datocms/generated";
import { GetItemsByGtinQuery } from "services/graphql/generated";
import st from "./ProductsSliderItem.module.scss";
import cx from "classnames";
import AddToCartButton from "components/addToCartButton";

type ProductsSliderItemProps = {
  datoProduct: ProductsSliderComponentFragment["products"][number];
  pepdirectProduct?: NonNullable<GetItemsByGtinQuery["items"]>["items"][number];
  handleLogging: () => void;
  currency: string;
  lowestPriceText: string;
  loading?: boolean;
};

export const ProductsSliderItem: FC<ProductsSliderItemProps> = memo(
  function Item({
    datoProduct,
    pepdirectProduct,
    handleLogging,
    currency,
    lowestPriceText,
    loading = false,
  }) {
    const { updateCartItem, cart } = useContext(CartContext);
    const { formatPriceByLocale } = useContext(LocalizationContext);
    const router = useRouter();
    const { addToCartEvent, removeFromCartEvent } = useCartAnalyticsEvents();
    const [updatingCart, setUpdatingCart] = useState(false);

    let quantity = 0;
    cart?.lineItems.forEach((lineItem) => {
      if (
        lineItem.__typename === "CartItem" &&
        lineItem.item.id === pepdirectProduct?.id
      ) {
        quantity = lineItem.quantity;
      }
    });

    const handleAdjustItemCount = async (add: boolean = true) => {
      setUpdatingCart(true);
      const newQuantity = quantity + (add ? 1 : -1);
      const ecommerce = getEcommerceWithValue({
        currency,
        itemListName: "Product slider",
        items: [
          {
            itemId: pepdirectProduct?.id,
            gtin: pepdirectProduct?.gtin ?? "",
            itemName: pepdirectProduct?.title ?? "",
            itemBrand: ITEM_BRAND,
            itemCategory: datoProduct.category?.analyticsCategoryName,
            price: pepdirectProduct?.price ? pepdirectProduct.price / 100 : 0,
            itemListName: "Product slider",
            currency,
            index: 0,
            quantity: 1,
          },
        ],
      });
      const newCart = await updateCartItem({
        productId: pepdirectProduct?.id,
        quantity: newQuantity,
        subscriptionIntervalInDays: null,
        analyticsDetails: ecommerce,
      });

      if (add) {
        await addToCartEvent(newCart, ecommerce);
      } else {
        await removeFromCartEvent(newCart, ecommerce);
      }

      setUpdatingCart(false);
    };

    const price = formatPriceByLocale(pepdirectProduct?.price || 0);
    const productLink = `/${datoProduct.category?.slug}/${datoProduct.slug}`;
    const outOfStock = isProductOutOfStock(pepdirectProduct);

    return (
      <div className={st.productItem}>
        <Link
          href={productLink}
          onClick={(event) => {
            event.preventDefault();
            handleLogging();
            router.push(productLink);
          }}
        >
          {datoProduct.productImages[0].responsiveImage && (
            <Image data={datoProduct.productImages[0].responsiveImage} />
          )}
          <p className={st.title}> {datoProduct.title}</p>
        </Link>
        {loading ? (
          <div
            className={cx(
              st.price,
              "rounded-md w-20 h-[22.5px] bg-gray-200 animate-pulse-opacity",
            )}
          />
        ) : (
          <p className={st.price}>{price}</p>
        )}
        <div className={st.lowestPrice}>{lowestPriceText}</div>

        <AddToCartButton
          updatingCart={updatingCart}
          quantity={quantity}
          outOfStock={outOfStock}
          handleAdjustItemCount={handleAdjustItemCount}
          loading={loading}
        />
      </div>
    );
  },
);
